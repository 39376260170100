.rounded {
  border-radius: 0.5rem !important;
}

.btn-primary {
  background-color: #295cf3;
  border-color: #295cf3;
}

.btn-primary:hover {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:disabled {
  color: #ffffff;
}

.btn-outline-primary {
  color: #295cf3;
  border-color: #295cf3;
}

.btn-outline-primary:hover {
  background-color: #295cf3;
  border-color: #295cf3;
}

.btn-outline-primary:disabled {
  color: #ffffff;
}

.card-title {
  margin-bottom: 0 !important;
}

.hover-scale {
  transform: scale(1);
  transition: transform 0.2s;
}

.hover-scale:hover {
  transform: scale(1.02);
}

.article-grid-card {
  height: 32rem;
}

.article-list-card {
  height: 14rem;
}

.article-grid-thumbnail {
  width: 100%;
  height: 14rem;
  object-fit: cover;
  overflow: hidden !important;
}

.article-grid-thumbnail:hover,
.article-list-thumbnail:hover  {
  cursor: pointer;
}

.article-grid-item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.article-grid-item-title,
.article-list-item-title {
  font-size: 18px !important;
  color: #000 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-grid-item-title:hover,
.article-list-item-title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.article-list-item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}